(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/casino/animate-header/assets/javascripts/animate-header.js') >= 0) return;  svs.modules.push('/components/casino/animate-header/assets/javascripts/animate-header.js');

'use strict';

const isMobile = svs.core.detect.formfactor.mobile();
const header = document.querySelector('.js-pnp-top-content');
const headerBottom = document.querySelector('.js-header-bottom');
const topMenu = document.querySelector('.js-casino-top-menu');
const topContentWrapper = document.querySelector('.js-top-content-wrapper');
const headerSolidGradient = 'linear-gradient(270deg, rgba(110, 20, 235, 1) 0%, rgba(158, 41, 255, 1) 35.42%, rgba(158, 41, 255, 1) 66.67%, rgba(110, 20, 235, 1) 100%)';
const topMenuBoxShadow = '0px 5px 22px 4px rgba(0, 0, 0, 0.12), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 7px 8px -4px rgba(0, 0, 0, 0.2)';
const topMenuBackground = 'rgba(42, 42, 64, 1)';

const leavingTopDistance = isMobile ? '+=50' : '+=100';
let mobileScrollTrigger;
const SCROLL_VELOCITY = -100; 

const mainTimeline = gsap.timeline({
  scrollTrigger: {
    trigger: document.querySelector('.pnp-background'),
    scrub: true,
    start: '0',
    end: leavingTopDistance,
    onUpdate: self => {
      if (isMobile && self.scroll() === 0) {
        topContentWrapper.classList.remove('animate-show-header', 'fixed-top-content', 'animation-active');
      }
    }
  }
});
function setupColorTimeline() {
  mainTimeline.to(header, {
    background: headerSolidGradient
  }).to(header, {
    'box-shadow': topMenuBoxShadow
  }, '<');
  if (topMenu) {
    mainTimeline.to(topMenu, {
      background: topMenuBackground
    }, '<').to(topMenu, {
      'box-shadow': topMenuBoxShadow
    }, '<');
  }
}
function init() {
  if (isMobile) {
    mobileScrollTrigger = ScrollTrigger.create({
      start: leavingTopDistance,
      onUpdate: self => {
        if (self.direction === -1) {
          self.getVelocity() < SCROLL_VELOCITY && topContentWrapper.classList.add('fixed-top-content', 'animate-show-header');
        } else {
          topContentWrapper.classList.remove('animate-show-header');
        }
      },
      onToggle: self => {
        if (self.isActive) {
          topContentWrapper.classList.add('fixed-top-content', 'animation-active');
        }
      }
    });
  }
  setupColorTimeline();
}

function disable() {
  let revert = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
  mainTimeline.scrollTrigger.disable(revert);
  if (mobileScrollTrigger) {
    mobileScrollTrigger.disable(revert);
  }
}

function enable() {
  let reset = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
  mainTimeline.scrollTrigger.enable(reset);
  if (mobileScrollTrigger) {
    mobileScrollTrigger.enable(reset);
  }
}
function toggleNavigation() {
  if (topMenu) {
    topMenu.classList.toggle('hide');
  }
}
function hideNavigation() {
  if (topMenu) {
    topMenu.classList.add('hide');
  }
}
function showNavigation() {
  if (topMenu) {
    topMenu.classList.remove('hide');
  }
}
function makeSolid() {
  disable(false);
  header && header.toggleAttribute('style');
  topContentWrapper.classList.remove('fixed-top-content');
  header.classList.remove('pnp-transparent-header');
}
function makeSolidForFilter() {
  disable(false);
  header.classList.remove('pnp-transparent-header');
}
function makeTransparent() {
  enable(false);
  header.classList.add('pnp-transparent-header');
}
function addHeaderBottomRelative() {
  headerBottom.classList.add('header-bottom-position-fixed');
}
function removeHeaderBottomRelative() {
  headerBottom.classList.remove('header-bottom-position-fixed');
}
setGlobal('svs.components.casino.animateHeader.init', init);
setGlobal('svs.components.casino.animateHeader.disable', disable);
setGlobal('svs.components.casino.animateHeader.enable', enable);
setGlobal('svs.components.casino.animateHeader.toggleNavigation', toggleNavigation);
setGlobal('svs.components.casino.animateHeader.showNavigation', showNavigation);
setGlobal('svs.components.casino.animateHeader.hideNavigation', hideNavigation);
setGlobal('svs.components.casino.animateHeader.makeSolid', makeSolid);
setGlobal('svs.components.casino.animateHeader.makeSolidForFilter', makeSolidForFilter);
setGlobal('svs.components.casino.animateHeader.makeTransparent', makeTransparent);
setGlobal('svs.components.casino.animateHeader.addHeaderBottomRelative', addHeaderBottomRelative);
setGlobal('svs.components.casino.animateHeader.removeHeaderBottomRelative', removeHeaderBottomRelative);

 })(window);